import * as React from "react";
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image";

import {
  Container,
  Item,
  ItemLink,
  Image,
  Content,
  Author,
  Title,
  Description,
} from "./Articles.styles";

export type Article = {
  excerpt: string;
  fields: {
    slug: string;
  };
  frontmatter: {
    date: string;
    description: string;
    thumbnail: IGatsbyImageData;
    title: string;
    author: string;
  };
};

export interface ArticlesProps {
  items: Article[];
}

const Articles: React.FC<ArticlesProps> = ({ items }) => {
  return (
    <Container>
      {items.map((item) => (
        <Item key={item.fields.slug}>
          <ItemLink to={item.fields.slug}>
            <Image>
              <GatsbyImage
                image={getImage(item.frontmatter.thumbnail)}
                alt={item.frontmatter.title}
              />
            </Image>
            <Content>
              <Author>
                <span>{item.frontmatter.author}</span>
                <div />
                <span>{item.frontmatter.date}</span>
              </Author>
              <Title>{item.frontmatter.title}</Title>
              <Description>{item.excerpt}</Description>
            </Content>
          </ItemLink>
        </Item>
      ))}
    </Container>
  );
};

export default Articles;
