import styled from "styled-components";
import { Link, GatsbyLinkProps } from "gatsby";

export const Container = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const Item = styled.li`
  box-shadow: ${(props) => props.theme.utils.boxShadowCard};
  flex: 1 1 calc(50% - 12px);
  margin-bottom: 16px;
  border-radius: 10px;

  @media (min-width: 768px) {
    &:nth-child(even) {
      margin-left: 24px;
    }
  }

  @media (min-width: 1366px) {
    max-width: 351px;
  }
`;

export const ItemLink = styled(Link)<GatsbyLinkProps<{}>>`
  display: flex;
  flex-direction: column;
  text-decoration: none;
`;

export const Image = styled.div`
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  overflow: hidden;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

export const Content = styled.div`
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: ${(props) => props.theme.pallette.background.paper};
  padding: 24px;
  flex: 1;
`;

export const Author = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 8px;
  }

  span {
    font-size: 12px;
    font-weight: 400;
    color: ${(props) => props.theme.pallette.text.secondary};
  }

  div {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.pallette.primary.main};
  }
`;

export const Title = styled.div`
  margin: 8px 0;
  font-size: 20px;
  font-weight: 700;
  color: ${(props) => props.theme.pallette.text.default};
  cursor: pointer;
  will-change: color;
  transition: color 300ms;

  &:hover {
    color: ${(props) => props.theme.pallette.primary.main};
  }
`;
export const Description = styled.p`
  margin: 0;
  margin-top: auto;
  font-size: 14px;
  font-weight: 300;
  color: ${(props) => props.theme.pallette.text.default};
`;
