import * as React from "react";

import { Icon } from "@components/atoms";

import { Container, Page, Button, Empty } from "./Pagination.styles";

export interface PaginationProps {
  currentPage: number;
  totalPages: number;
  nextPage: string;
  previousPage: string;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  nextPage,
  previousPage,
}) => {
  if (totalPages === 1) return null;

  return (
    <Container>
      {previousPage ? (
        <Button to={previousPage}>
          <Icon size={14} name="chevron-left" />
        </Button>
      ) : (
        <Empty />
      )}
      <Page>
        Page <b>{currentPage}</b> of <b>{totalPages}</b>
      </Page>
      {nextPage ? (
        <Button to={nextPage}>
          <Icon size={14} name="chevron-right" />
        </Button>
      ) : (
        <Empty />
      )}
    </Container>
  );
};

export default Pagination;
