import React from "react";
import { graphql } from "gatsby";

import Pagination from "@components/Pagination";
import Widgets from "@components/Widgets";
import Seo from "@components/Seo";
import Articles from "@components/Articles";
import Categories from "@components/Categories";

const Category = ({ pageContext, data }) => {
  const { category, pageCurrent, pageTotal, nextPage, previousPage } =
    pageContext;
  const { nodes } = data.allMdx;
  const title = `${category} Articles`;
  return (
    <>
      <Seo
        title={title}
        description={`Collection of our ${category} articles. Master the ${category} by reading through our in-depth articles.`}
      />
      <Widgets>
        <Categories />
        <Articles items={nodes} />
        <Pagination
          currentPage={pageCurrent}
          totalPages={pageTotal}
          nextPage={nextPage}
          previousPage={previousPage}
        />
      </Widgets>
    </>
  );
};

export default Category;

export const pageQuery = graphql`
  query ($category: String!, $skip: Int!, $limit: Int!) {
    allMdx(
      filter: { frontmatter: { category: { eq: $category } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        excerpt(pruneLength: 160)
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          description
          author
          thumbnail {
            childImageSharp {
              gatsbyImageData(width: 1024)
            }
          }
        }
      }
    }
  }
`;
