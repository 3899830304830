import styled from "styled-components";

export const Container = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const Item = styled.li`
  background-color: ${(props) => props.theme.pallette.background.paper};
  border-radius: ${(props) => props.theme.utils.borderRadius};
  box-shadow: ${(props) => props.theme.utils.boxShadowCard};
  padding: 10px 14px;
  font-weight: 600;

  &:not(:last-child) {
    margin-right: 16px;
  }

  &.active {
    background-color: ${(props) => props.theme.pallette.primary.main};
    color: #ffffff;
    box-shadow: ${(props) => props.theme.utils.boxShadowPrimary};
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;
