import styled from "styled-components";
import { Link, GatsbyLinkProps } from "gatsby";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.pallette.background.paper};
  border-radius: ${(props) => props.theme.utils.borderRadius};
  box-shadow: ${(props) => props.theme.utils.boxShadowCard};
  padding: 4px;
  margin: 0 auto;
  max-width: 400px;
`;

export const Page = styled.div`
  color: ${(props) => props.theme.pallette.text.default};
  font-size: 14px;
`;

export const Empty = styled.div`
  min-width: 80px;
`;

export const Button = styled(Link)<GatsbyLinkProps<{}>>`
  text-decoration: none;
  color: ${(props) => props.theme.pallette.text.button};
  border-radius: ${(props) => props.theme.utils.borderRadius};
  background-color: ${(props) => props.theme.pallette.background.default};
  padding: 14px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
  will-change: background-color;
  font-size: 0;
  width: 80px;

  &:hover,
  &:active,
  &:focus {
    background-color: ${(props) => props.theme.pallette.primary.main};

    svg {
      fill: ${(props) => props.theme.pallette.text.button};
    }
  }

  svg {
    fill: ${(props) => props.theme.pallette.text.default};
    transition: all 0.3s;
  }
`;
