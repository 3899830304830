import * as React from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import cn from "classnames";

import { useCategories } from "@hooks";

import { Container, Item } from "./Categories.styles";

const Categories: React.FunctionComponent = () => {
  const categories = useCategories();
  const { pathname } = useLocation();

  return (
    <Container>
      {categories.map((item) => (
        <Item
          className={cn({ active: pathname.includes(`category/${item.path}`) })}
          key={item.path}
        >
          <Link to={`/category/${item.path}`}>{item.label}</Link>
        </Item>
      ))}
    </Container>
  );
};

export default Categories;
